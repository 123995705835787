Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.itemListApiContentType = "application/json";
exports.urlGetItemList = "bx_block_order_reservations/reservation_services"
exports.itemListApiMethodType = "GET";

exports.urlSecureReservationList = "bx_block_order_reservations/account_orders";
exports.secureReservationApiMethodType = "POST";

exports.urlGetReservationHistory = "bx_block_order_reservations/account_orders"
exports.getReservationHistoryApiMethodType = "GET";

exports.urlEditReservation = "bx_block_order_reservations/account_orders/"
exports.editReservationApiMethodType = "PUT";

exports.urlCancelReservation = "bx_block_order_reservations/account_orders/"
exports.cancelReservationApiMethodType = "DELETE";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reservations";
exports.labelBodyText = "Reservations Services List";
exports.txtInputPlaceholder = "quantity";
exports.totalPrice = "Total Price";
exports.edit = "Edit";
exports.delete = "Cancel";
exports.reservationStatus = "Reservation Status";

exports.btnExampleTitle = "Secure Reservation";
exports.btnEditTitle = "Edit";
exports.btnCancelTitle = "Cancel";
exports.btnHistoryExampleTitle = "Go To Reservation History";
exports.textquantityplaceholder = "quantity";

exports.txtOrderId = "Order id";
exports.orderPlacedDate = "Order Date";
exports.sortBy = "Sort By";
exports.search = "Search";
exports.accountsAPiEndPoint = "bx_block_login/logins"
// Customizable Area End