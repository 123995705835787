import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  imgTick,
  imgTickActive
} from "./assets";
import { CommonActions } from "@react-navigation/native";
import { AsyncStorage } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  termsAndConditionAccepted: boolean;
  emailError: string;
  passwordError: string;
  rePasswordError: string;
  tcError: string;
  showSuccessOverlay: boolean;
  showTermsConditionOverlay: boolean;
  showPrivacyOverlay: boolean;
  termsConditionData: string;
  privacyPolicyData: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  charReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  privacyPolicyApiCallId: string = "";
  termsConditionApiCallId: string = "";
  emailAvailabilityApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  defaultFirstName: any;
  defaultLastName: any;
  defaultLabelEmail: any;
  defaultLabelPassword: any;

  mailErrorNull: any;
  mailErrorFormat: any;
  passwordNull: any;
  passwordFormat: any;
  passwordMatch: any;

  login: string;
  signUp: string;
  skip: string;
  or: string;
  haveAnAccount: string;

  currentCountryCode: any;
  lastName: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      termsAndConditionAccepted: true,
      emailError: "",
      passwordError: "",
      rePasswordError: "",
      tcError: "",
      showSuccessOverlay: false,
      showTermsConditionOverlay: false,
      showPrivacyOverlay: false,
      termsConditionData: "",
      privacyPolicyData: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#!@$&*?<>',\\}{=\-)(^%`~+.:;_])[A-Za-z\d#!@$&*?<>',\\}{=\-)(^%`~+.:;_]{8,}$/
    );
    this.emailReg = new RegExp(/^(?:[a-zA-Z][a-zA-Z0-9]*)(?:\.[a-zA-Z][a-zA-Z0-9]*)*@(?:(?:[a-zA-Z0-9]+\.)+[a-zA-Z]{2,})$/);
    this.charReg = new RegExp(/^[a-zA-Z][a-zA-Z]*\s*$/);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.defaultFirstName = configJSON.defaultFirstName;
    this.defaultLastName = configJSON.defaultLastName;
    this.defaultLabelEmail = configJSON.defaultLabelEmail;
    this.defaultLabelPassword = configJSON.defaultLabelPassword;

    this.mailErrorNull = configJSON.mailErrorNull;
    this.mailErrorFormat = configJSON.mailErrorFormat;
    this.passwordNull = configJSON.passwordNull;
    this.passwordFormat = configJSON.passwordFormat;
    this.passwordMatch = configJSON.passwordMatch;

    this.login = configJSON.login;
    this.signUp = configJSON.signUp;
    this.skip = configJSON.skip;
    this.or = configJSON.or;
    this.haveAnAccount = configJSON.haveAnAccount;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        this.handleCreateAccontResponse(responseJson,apiRequestCallId)
        
        if (apiRequestCallId && responseJson && apiRequestCallId === this.privacyPolicyApiCallId) {
          this.setState({
            privacyPolicyData: responseJson.data.description || ""
          }); 
        }
        if (apiRequestCallId && responseJson && apiRequestCallId === this.termsConditionApiCallId) {
          this.setState({
            termsConditionData: responseJson.data.description || ""
          });
        }
        if (apiRequestCallId && responseJson && apiRequestCallId === this.emailAvailabilityApiCallId && responseJson.errors && responseJson.errors[0] && responseJson.errors[0].account && responseJson.errors[0].account !== "Account Doesn't Exist")  {
              this.setState({ emailError: "Account already exists." });
        }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      await this.storeData(responseJson);

      this.send(msg);
    }
  }

  storeData = async (responseJson: any) => {
    await AsyncStorage.setItem("SESSION_DATA", JSON.stringify(responseJson));
  }


  goToPrivacyPolicy() {
    this.setState({ showPrivacyOverlay: true });
  }

  goToTermsAndCondition() {
    this.setState({ showTermsConditionOverlay: true });
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    console.log(email.replace(/\s+$/, ''))
    return this.emailReg.test(email.replace(/^\s+|\s+$|(?!^)\s+/g, ''));
  }
  isValidPassword(pass: string) {
    return this.passwordReg.test(pass);
  }

  isEmailValid(data?: string) {
    if (this.isStringNullOrBlank(data || this.state.email)) {
      this.setState({ emailError: configJSON.mailErrorNull });
    } else if (!this.isValidEmail(data || this.state.email)) {
      this.setState({ emailError: configJSON.mailErrorFormat });
    } else {
      this.setState({ emailError: "" });
      this.getEmailAvailability();
    }
  }

  isPasswordValid(data?: string) {
    if (this.isStringNullOrBlank(data || this.state.password)) {
      this.setState({ passwordError: configJSON.passwordNull });
    } else if (!this.isValidPassword(data || this.state.password)) {
      this.setState({ passwordError: configJSON.passwordFormat });
    } else {
      this.setState({ passwordError: "" });
    }
  }

  isConfirmPasswordValid(data?: string) {
    if (this.isStringNullOrBlank(data || this.state.reTypePassword)) {
      this.setState({ rePasswordError: configJSON.passwordNull });
    } else if ((data || this.state.password) !== this.state.reTypePassword) {
      this.setState({ rePasswordError: configJSON.errorBothPasswordsNotSame });
    } else {
      this.setState({ rePasswordError: "" });
    }
  }
  validateData() {
    this.isEmailValid();
    this.isPasswordValid();
    this.isConfirmPasswordValid();

    if (!this.state.termsAndConditionAccepted) {
      this.setState({ tcError: configJSON.termsAndConditionError });
    } else {
      this.setState({ tcError: "" });
    }
  }

  createAccount(): boolean {
    this.validateData();
    if (
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      return false;
    }else if (!this.isValidEmail(this.state.email)) {
      return false;
    }else if (!this.passwordReg.test(this.state.password)) {
      return false;
    }else if (this.state.password !== this.state.reTypePassword) {
      return false;
    }else if (!this.state.termsAndConditionAccepted) {
      return false;
    }else{
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };
  
      const attrs = {      
        email: this.state.email,
        password: this.state.password,
        confirm_password: this.state.reTypePassword
      };
  
      const data = {
        type: "email_account",
        attributes: attrs
      };
  
      const httpBody = {
        data: data
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountsAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  getPrivacyPolicy() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getPrivacyPolicyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = getPrivacyPolicyMsg.messageId;

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetPrivacyPolicy
    );

    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrivacyPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPrivacyPolicyMsg.id, getPrivacyPolicyMsg);
  }

  getTermsAndCondition() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getTermsAndConditionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsConditionApiCallId = getTermsAndConditionMsg.messageId;

    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetTermsAndCondition
    );

    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTermsAndConditionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTermsAndConditionMsg.id, getTermsAndConditionMsg);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };
  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgTermsAndConditionProps = {
    source: imgTickActive
  };

  btnTermsAndConditionProps = {
    onPress: () => {
      this.imgTermsAndConditionProps.source = !this.state
        .termsAndConditionAccepted
        ? imgTickActive
        : imgTick;
      if (this.state.termsAndConditionAccepted) {
        this.setState({ tcError: configJSON.termsAndConditionError });
      } else {
        this.setState({ tcError: "" });
      }
      this.setState({
        termsAndConditionAccepted: !this.state.termsAndConditionAccepted
      });
    }
  };


  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text.replace(/^\s+|\s+$|(?!^)\s+/g, '') });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text.replace(/^\s+|\s+$|(?!^)\s+/g, '');
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
  };

  txtInputEmailPrpos = this.txtInputEmailMobilePrpos;

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });
      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  onPressLogin() {
    const data: any = {
      screenType: "login"
    }

    if (this.props.route.params && this.props.route.params.type && this.props.route.params.key) {
      this.props.navigation.goBack();
      return;
    }
    this.props.navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: "LandingScreen", params: data }]
      })
    );
  }

  getEmailAvailability() {
    const headers = {
      "Content-Type": "application/json"
    };

    const httpBody = {
      data: {
        email: this.state.email
      }
    };

    const getEmailAvailabilityMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailAvailabilityApiCallId = getEmailAvailabilityMsg.messageId;

    getEmailAvailabilityMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/verify_email"
    );

    getEmailAvailabilityMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getEmailAvailabilityMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getEmailAvailabilityMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    console.log("email aailability ------", getEmailAvailabilityMsg);
    runEngine.sendMessage(getEmailAvailabilityMsg.id, getEmailAvailabilityMsg);
  }

  handleCreateAccontResponse = (responseJson:any, apiRequestCallId:string) =>{
    if (apiRequestCallId === this.createAccountApiCallId && !responseJson.errors && responseJson.email && responseJson.email[0] && responseJson.email[0] === "has already been taken") {
      this.setState({ emailError: "Account already exists." });
    } else if(apiRequestCallId === this.createAccountApiCallId && !responseJson.errors){
      const ONOBARDING_DONE = "onBoardingCompleted";
          AsyncStorage.setItem(ONOBARDING_DONE, JSON.stringify(true))
            .then(() => {
              this.saveLoggedInUserData(responseJson);
              this.setState({ showSuccessOverlay: true });
            })
            .catch(error => {
              console.log("error:", error);
            });
    }else if(apiRequestCallId === this.createAccountApiCallId && responseJson.errors){
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Customizable Area End
}
