import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Image,
  StatusBar,
  KeyboardAvoidingView
} from "react-native";
import { Overlay } from "react-native-elements";

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { background_image, imgClose, imgGoBack } from "./assets";
import { deviceBasedDynamicDimension } from "../../utilities/src/CommonFunctions";
// Customizable Area End

import MobileAccountLoginController, {
  Props
} from "./MobileAccountLoginController";

export default class MobileAccountLoginBlock extends MobileAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : "height"}
        style={styles.keyboardPadding}
      >
        <StatusBar
          backgroundColor={"rgba(18, 18, 18, 1)"}
          barStyle="light-content"
        />
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID="Background"
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            <View>
              <View style={styles.headerView}>
                <TouchableOpacity
                  testID="goBackButton"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                >
                  <Image
                    testID={"createAccountGoBackImage"}
                    style={styles.imagenav_ButtonImageNav}
                    source={imgGoBack}
                  />
                </TouchableOpacity>
                <Text style={styles.headerTextStyle}>{this.labelHeader}</Text>
              </View>

              {this.state.showImage && (
                <Image
                  data-elementId="image_LoginVector"
                  style={styles.image_background}
                  source={background_image}
                />
              )}

              <View>
                <Text style={styles.lableTitle}>{this.labelTitle}</Text>
                <Text style={styles.lableSubTitle}>
                  You will receive a 4-digit Verification Code on your Mobile
                  Number.
                </Text>
              </View>
              <View>
                <View style={styles.areaMobileContainer}>
                  {/* <View style={styles.scrollViewContainer}> */}

                  <CountryCodeSelector
                    navigation={navigation}
                    id={"CountryCodeSelector"}
                    placeHolder={this.placeHolderSelectCountry}
                    style={
                      this.state.countryCodeSelected !== ""
                        ? styles.countryPickerSelectedStyle
                        : styles.countryPickerStyle
                    }
                    textStyle={styles.countryPickerTextStyle}
                    disable={true}
                    value={this.state.countryCodeSelected}
                    imageStyle={styles.countryPickerImageStyle}
                  />

                  <TextInput
                    testID="txtInputPhoneNumber"
                    style={
                      this.state.countryCodeSelected !== ""
                        ? styles.bgInputPhoneNumberSelected
                        : styles.bgInputPhoneNumber
                    }
                    placeholder={this.defaultPhoneNumber}
                    value={this.state.mobileNo}
                    onChangeText={text => this.setState({ mobileNo: text })}
                    autoCompleteType="tel"
                    keyboardType="phone-pad"
                    placeholderTextColor={"rgba(180, 180, 180, 1)"}
                    maxLength={10}
                  />
                </View>
                {!this.isStringNullOrBlank(this.state.mobileError) && (
                  <Text style={styles.errorTextStyle}>{this.state.mobileError}</Text>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>

        <View style={styles.step2ViewBottom}>
          <View style={styles.termsAndConditionView}>
            <TouchableOpacity testID="TCcheck" {...this.btnTermsAndConditionProps}>
              {!this.state.termsAndConditionAccepted ? (
                <View testID="emptyTC" style={styles.checkBoxEmptyStyle} />
              ) : (
                <Image
                  testID={"imgEnableRePasswordField"}
                  style={styles.tcImageStyle}
                  {...this.imgTermsAndConditionProps}
                />
              )}
            </TouchableOpacity>
            <Text style={styles.tcTextStyle}>
              {`${"I agree to the"} `}
              <Text
                testID={"btnLegalTermsAndCondition"}
                style={styles.tcInnerTextStyle}
                {...this.btnLegalTermsAndConditionProps}
              >
                {this.labelLegalTermCondition}
              </Text>
              {`${" and "}`}
              <Text
                testID={"btnLegalPrivacyPolicy"}
                style={styles.ppInnerTextStyle}
                {...this.btnLegalPrivacyPolicyProps}
              >
                {this.labelLegalPrivacyPolicy}
              </Text>
            </Text>
          </View>
          <View style={{ alignSelf: "flex-start" }}>
            {!this.isStringNullOrBlank(this.state.tcError) && (
              <Text style={styles.errorTC}>{this.state.tcError}</Text>
            )}
          </View>

          <TouchableOpacity
            testID={"btnSendOtp"}
            onPress={() => this.doMobileLogIn()}
            // onPress={() => this.props.navigation.navigate("OTPInputAuth")}
            style={styles.signUpButtonStyle}
          >
            <Text style={styles.signUpButtonTextStyle}>{this.btnTxtLogin}</Text>
          </TouchableOpacity>
          {!this.state.showImage && (
            <View
            testID="imagePaddingView"
              style={{ marginBottom: deviceBasedDynamicDimension(24, true, 1) }}
            />
          )}
        </View>
        <Overlay
          isVisible={this.state.showTermsConditionOverlay}
          overlayBackgroundColor={"rgba(64,64,64, 1)"}
          width={"100%"}
          height={"80%"}
          overlayStyle={{
            position: "absolute",
            bottom: 0,
            maxHeight: "95%",
            borderTopLeftRadius: deviceBasedDynamicDimension(15, true, 1),
            borderTopRightRadius: deviceBasedDynamicDimension(15, true, 1)
          }}
        >
          <View style={styles.overlayContainer}>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "rgba(180, 180, 180, 1)",
                borderBottomWidth: 1
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={styles.termsHeader}>
                  {"Terms & Conditions"}
                </Text>
              </View>
              <TouchableOpacity
                testID="TCClose"
                onPress={() => {
                  this.setState({ showTermsConditionOverlay: false });
                }}
              >
                <Image
                  testID={"imgCloseOverlay"}
                  style={styles.imgClose}
                  source={imgClose}
                />
              </TouchableOpacity>
            </View>
            <ScrollView>
              <View style={{ marginTop: 10 }}>
                <Text style={styles.termsText}>
                  {this.state.termsConditionData}
                </Text>
              </View>
            </ScrollView>
          </View>
        </Overlay>

        <Overlay
          isVisible={this.state.showPrivacyOverlay}
          overlayBackgroundColor={"rgba(64,64,64, 1)"}
          width={"100%"}
          height={"80%"}
          overlayStyle={{
            position: "absolute",
            bottom: 0,
            maxHeight: "95%",
            borderTopLeftRadius: deviceBasedDynamicDimension(15, true, 1),
            borderTopRightRadius: deviceBasedDynamicDimension(15, true, 1)
          }}
        >
          <View style={styles.overlayContainer}>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "rgba(180, 180, 180, 1)",
                borderBottomWidth: 1
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={styles.termsHeader}>{"Privacy Policy"}</Text>
              </View>

              <TouchableOpacity
                testID="closePP"
                onPress={() => {
                  this.setState({ showPrivacyOverlay: false });
                }}
              >
                <Image
                  testID={"imgCloseOverlay"}
                  style={styles.imgClose}
                  source={imgClose}
                />
              </TouchableOpacity>
            </View>
            <ScrollView>
              <View style={{ marginTop: 10 }}>
                <Text style={styles.termsText}>
                  {this.state.privacyPolicyData}
                </Text>
              </View>
            </ScrollView>
          </View>
        </Overlay>
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  keyboardPadding: { flex: 1 },
  mainContainer: {
    flex: 1,
    alignItems: "center"
  },
  container: {
    flex: 1,
    paddingHorizontal: deviceBasedDynamicDimension(16, true, 1),
    paddingVertical: deviceBasedDynamicDimension(13, false, 1),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "rgba(18, 18, 18, 1)"
  },
  headerView: {
    marginTop: deviceBasedDynamicDimension(10, false, 1),
    flexDirection: "row",
    marginBottom: deviceBasedDynamicDimension(32, false, 1),
    alignItems: "center"
  },
  imagenav_ButtonImageNav: {
    width: deviceBasedDynamicDimension(24, true, 1),
    height: deviceBasedDynamicDimension(24, true, 1),
    resizeMode: "contain",
    marginRight: deviceBasedDynamicDimension(16, true, 1)
  },
  headerTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(18, true, 1)
  },
  lableTitle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    fontFamily: "Poppins-Medium",
    fontSize: deviceBasedDynamicDimension(21, true, 1),
    marginBottom: deviceBasedDynamicDimension(7, false, 1)
  },
  lableSubTitle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(146, 146, 146, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1)
  },
  bgInput: {
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#fff",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderTopRightRadius: deviceBasedDynamicDimension(12, true, 1),
    borderBottomRightRadius: deviceBasedDynamicDimension(12, true, 1),
    paddingLeft: deviceBasedDynamicDimension(12, true, 1),
    width: "80%",
    height: deviceBasedDynamicDimension(45, false, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  step2ViewBottom: {
    backgroundColor: "rgba(18, 18, 18, 1)",
    paddingHorizontal: deviceBasedDynamicDimension(16, true, 1),
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: deviceBasedDynamicDimension(20, false, 1)
  },
  
  tcTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(146, 146, 146, 1)",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1)
  },
  tcInnerTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1
  },
  ppInnerTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1
  },
  signUpButtonStyle: {
    width: "100%",
    backgroundColor: "rgba(255, 182, 54, 1)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1)
  },

  termsAndConditionView: {
    flexDirection: "row",
    marginTop: deviceBasedDynamicDimension(10, false, 1),
    marginBottom: deviceBasedDynamicDimension(20, false, 1),
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start"
  },
  orWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: deviceBasedDynamicDimension(25, false, 1)
  },
  horizontalLine: {
    flex: 1,
    height: 1,
    backgroundColor: "rgba(246, 246, 246, 0.2)"
  },
  tcImageStyle: {
    width: deviceBasedDynamicDimension(20, true, 1),
    height: deviceBasedDynamicDimension(20, true, 1),
    resizeMode: "contain",
    marginRight: deviceBasedDynamicDimension(10, true, 1),
    borderRadius: deviceBasedDynamicDimension(15, true, 1),
    marginBottom: 5
  },

  signUpButtonTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(32, 32, 32, 1)",
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(16, true, 1),
    paddingVertical: deviceBasedDynamicDimension(16, false, 1)
  },

  bgMobileInput: {
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(16, true, 1),
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: deviceBasedDynamicDimension(64, false, 1),
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: deviceBasedDynamicDimension(2, true, 1),
    includeFontPadding: true,
    padding: deviceBasedDynamicDimension(10, true, 1)
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    padding: deviceBasedDynamicDimension(10, true, 1),
    zIndex: 999
  },
  viewContainer: {
    zIndex: -1
  },
  scrollViewContainer: {
    flex: 1,
    marginRight:  0
  },
  image_background: {
    width: "100%",
    height: deviceBasedDynamicDimension(250, false, 1),
    opacity: 1,
    resizeMode: "contain",
    marginTop: deviceBasedDynamicDimension(25, false, 1),
    marginBottom: deviceBasedDynamicDimension(50, false, 1),
    alignSelf: "center"
  },
  areaMobileContainer:{ flexDirection: "row", marginVertical: 1 },
  countryPickerStyle: {
    flexDirection: "row",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderTopLeftRadius: deviceBasedDynamicDimension(12, true, 1),
    borderBottomLeftRadius: deviceBasedDynamicDimension(12, true, 1),
    // paddingLeft: 25,
    height: deviceBasedDynamicDimension(45, false, 1),
    width: "40%",
    alignItems: "center"
  },
  countryPickerSelectedStyle: {
    flexDirection: "row",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderTopLeftRadius: deviceBasedDynamicDimension(12, true, 1),
    borderBottomLeftRadius: deviceBasedDynamicDimension(12, true, 1),
    // paddingLeft: 25,
    width: "30%",
    alignItems: "center",
    justifyContent: "center"
  },
  countryPickerTextStyle: {
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "rgba(180, 180, 180, 1)",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    paddingLeft: deviceBasedDynamicDimension(16, true, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  countryPickerImageStyle: {
    width: deviceBasedDynamicDimension(16, true, 1),
    height: deviceBasedDynamicDimension(16, true, 1),
    resizeMode: "contain",
    marginLeft: deviceBasedDynamicDimension(5, true, 1)
  },
  bgInputPhoneNumber: {
    textAlign: "left",
    color: "#fff",
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderWidth: 1,
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    borderTopRightRadius: deviceBasedDynamicDimension(12, true, 1),
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderBottomRightRadius: deviceBasedDynamicDimension(12, true, 1),
    width: "60%",
    paddingLeft: deviceBasedDynamicDimension(12, true, 1),
    fontStyle: "normal",
    includeFontPadding: false,
    fontWeight: "normal",
    fontFamily: "Poppins-Regular"
  },
  bgInputPhoneNumberSelected: {
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    textAlign: "left",
    color: "#fff",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderWidth: 1,
    borderBottomRightRadius: deviceBasedDynamicDimension(12, true, 1),
    borderTopRightRadius: deviceBasedDynamicDimension(12, true, 1),
    paddingLeft: deviceBasedDynamicDimension(12, true, 1),
    fontStyle: "normal",
    width: "70%",
    fontWeight: "normal",
    includeFontPadding: false,
    textAlignVertical: "center",
    fontFamily: "Poppins-Regular"
  },
  errorTextStyle: {
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#FF3B30",
    textAlign: "left",
    marginTop: deviceBasedDynamicDimension(-5, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  errorTC: {
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#FF3B30",
    textAlign: "left",
    marginTop: deviceBasedDynamicDimension(-20, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  overlayContainer: {
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: deviceBasedDynamicDimension(15, true, 1),
    borderTopRightRadius: deviceBasedDynamicDimension(15, true, 1)
  },
  imgClose: {
    width: deviceBasedDynamicDimension(25, true, 1),
    height: deviceBasedDynamicDimension(25, true, 1),
    resizeMode: "contain"
  },
  termsHeader: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(16, true, 1),
    lineHeight: deviceBasedDynamicDimension(21, true, 1),
    marginVertical: deviceBasedDynamicDimension(15, false, 1)
  },
  termsText: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    lineHeight: deviceBasedDynamicDimension(19, true, 1),
    letterSpacing: 0.07,
    marginVertical: deviceBasedDynamicDimension(10, false, 1)
  },
  checkBoxEmptyStyle: {
    width: deviceBasedDynamicDimension(20, true, 1),
    height: deviceBasedDynamicDimension(20, true, 1),
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.23)",
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: deviceBasedDynamicDimension(5, true, 1),
    borderWidth: 1,
    marginRight: deviceBasedDynamicDimension(10, true, 1)
  }
  // Customizable Area End
});
