import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView
} from "react-native";
import { Overlay } from "react-native-elements";
import { imgClose, imgGoBack, imgSuccess } from "./assets";
import { deviceBasedDynamicDimension } from "../../utilities/src/CommonFunctions";
import { horizontalScale } from "../../../components/src/Scale";
import { CommonActions } from "@react-navigation/native";

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            <KeyboardAvoidingView
              testID="KeyBoardAvoidView"
              behavior={this.isPlatformiOS() ? "padding" : "height"}
              style={styles.keyboardPadding}
            >

              <ScrollView contentContainerStyle={styles.scrollContainer}
                keyboardShouldPersistTaps="always" >
                <TouchableWithoutFeedback>
                  {/* Customizable Area Start */}
                  <View style={styles.flex1}>
                    <View>
                      <View style={styles.headerView}>
                        <TouchableOpacity
                          testID="GoBack"
                          onPress={() => {
                            this.props.navigation.goBack();
                          }}
                        >
                          <Image
                            testID={"createAccountGoBackImage"}
                            style={styles.imagenav_ButtonImageNav}
                            source={imgGoBack}
                          />
                        </TouchableOpacity>
                        <Text testID="ScreenTitle" style={styles.headerTextStyle}>
                          {this.labelHeader}
                        </Text>
                      </View>

                      <View>
                        <Text testID={"txtLabelEmail"} style={styles.lableTitle}>
                          {this.labelEmail}
                        </Text>
                        <TextInput
                          testID={"txtInputEmail"}
                          style={
                            styles.bgInput
                          }
                          placeholder={this.defaultLabelEmail}
                          value={this.state.email}
                          keyboardType={"email-address"}
                          placeholderTextColor={"rgba(180, 180, 180, 1)"}
                          onFocus={() => {
                            this.setState({ emailError: "" });
                          }}
                          onBlur={() => {
                            this.isEmailValid();
                          }}
                          {...this.txtInputEmailPrpos} //Merge Engine::From BDS - {...this.testIDProps}
                        />
                        {!this.isStringNullOrBlank(this.state.emailError) && (
                          <Text testID="txtErrorEmail" style={styles.errorText}>
                            {this.state.emailError}
                          </Text>
                        )}
                      </View>

                      <View testID="passwordWrapper">
                        <Text testID={"txtLabelPassword"} style={styles.lableTitle}>
                          {this.labelPassword}
                        </Text>
                        <View style={styles.bgPasswordContainer}>
                          <TextInput
                            testID={"txtInputPassword"}
                            style={styles.bgPasswordInput}
                            placeholder={this.defaultLabelPassword}
                            value={this.state.password}
                            placeholderTextColor={"rgba(180, 180, 180, 1)"}
                            maxLength={20}
                            onFocus={() => {
                              this.setState({ passwordError: "" });
                            }}
                            onBlur={() => {
                              this.isPasswordValid();
                            }}
                            {...this.txtInputPasswordProps}
                          />

                          <TouchableOpacity
                            testID={"btnPasswordShowHide"}
                            style={styles.passwordShowHide}
                            {...this.btnPasswordShowHideProps}
                          >
                            <Image
                              testID={"imgEnablePasswordField"}
                              style={styles.imgPasswordShowhide}
                              {...this.imgEnablePasswordFieldProps}
                            />
                          </TouchableOpacity>
                        </View>
                        {!this.isStringNullOrBlank(this.state.passwordError) && (
                          <Text testID="txtErrorPassword" style={styles.errorText}>
                            {this.state.passwordError}
                          </Text>
                        )}
                      </View>

                      <View testID="confirmPasswordWrapper">
                        <Text
                          testID={"txtLabelConfirmPassword"}
                          style={styles.lableTitle}
                        >
                          {this.labelRePassword}
                        </Text>
                        <View style={styles.bgPasswordContainer}>
                          <TextInput
                            testID={"txtInputConfirmPassword"}
                            style={styles.bgPasswordInput}
                            placeholder={this.defaultLabelPassword}
                            value={this.state.reTypePassword}
                            placeholderTextColor={"rgba(180, 180, 180, 1)"}
                            maxLength={20}
                            onFocus={() => {
                              this.setState({ rePasswordError: "" });
                            }}
                            onBlur={() => {
                              this.isConfirmPasswordValid();
                            }}
                            {...this.txtInputConfirmPasswordProps}
                          />

                          <TouchableOpacity
                            testID={"btnConfirmPasswordShowHide"}
                            style={styles.passwordShowHide}
                            {...this.btnConfirmPasswordShowHideProps}
                          >
                            <Image
                              testID={"imgEnableRePasswordField"}
                              style={styles.imgPasswordShowhide}
                              {...this.imgEnableRePasswordFieldProps}
                            />
                          </TouchableOpacity>
                        </View>
                        {!this.isStringNullOrBlank(this.state.rePasswordError) && (
                          <Text
                            testID="txtErrorConfirmPassword"
                            style={styles.errorText}
                          >
                            {this.state.rePasswordError}
                          </Text>
                        )}
                      </View>
                    </View>


                    <Overlay
                      isVisible={this.state.showSuccessOverlay}
                      overlayBackgroundColor={"rgba(64,64,64, 1)"}
                      height={"30%"}
                      width={"95%"}
                      borderRadius={15}
                    >
                      <View style={styles.overlayContainer}>
                        <Image
                          testID={"imgAccountCreatedSuccessfully"}
                          style={styles.successImg}
                          source={imgSuccess}
                        />

                        <Text style={styles.successText}>
                          {"Account created Successully!"}
                        </Text>

                        <TouchableOpacity
                          testID="successDone"
                          style={styles.doneButtonStyle}
                          onPress={async () => {
                            this.setState({
                              showSuccessOverlay: false
                            });
                            //only navigate to WalletKYC if successfuloverly hidden

                            if (this.props.route.params && this.props.route.params.type && this.props.route.params.key) {
                              this.props.navigation.navigate('ReviewSeatApplication', this.props.route.params);
                            } else {
                              this.props.navigation.dispatch(
                                CommonActions.reset({
                                  index: 0,
                                  routes: [{ name: "TabNavigation" }],
                                })
                              );
                            }
                          }}
                        >
                          <Text style={styles.signUpButtonTextStyle}>{"Done"}</Text>
                        </TouchableOpacity>
                      </View>
                    </Overlay>

                    <Overlay
                      isVisible={this.state.showTermsConditionOverlay}
                      overlayBackgroundColor={"rgba(64,64,64, 1)"}
                      width={"100%"}
                      height={"80%"}
                      borderRadius={15}
                      overlayStyle={styles.overlayStyle}
                    >
                      <View style={styles.overlayContainerPrivacy}>
                        <View style={styles.overlayContainerHeader}>
                          <View style={styles.flex1}>
                            <Text style={styles.termsTextHeader}>
                              {"Terms & Conditions"}
                            </Text>
                          </View>
                          <TouchableOpacity
                            testID="colseTC"
                            onPress={() => {
                              this.setState({ showTermsConditionOverlay: false });
                            }}
                          >
                            <Image
                              testID={"imgCloseOverlay"}
                              style={styles.closeImg}
                              source={imgClose}
                            />
                          </TouchableOpacity>
                        </View>
                        <ScrollView>
                          <View style={styles.margin10}>
                            <Text style={styles.termsText}>
                              {this.state.termsConditionData}
                            </Text>
                          </View>
                        </ScrollView>
                      </View>
                    </Overlay>

                    <Overlay
                      isVisible={this.state.showPrivacyOverlay}
                      overlayBackgroundColor={"rgba(64,64,64, 1)"}
                      width={"100%"}
                      height={"80%"}
                      borderRadius={15}
                      overlayStyle={styles.overlayStyle}
                    >
                      <View style={styles.overlayContainerPrivacy}>
                        <View style={styles.overlayContainerHeader}>
                          <View style={styles.flex1}>
                            <Text style={styles.termsTextHeader}>
                              {"Privacy Policy"}
                            </Text>
                          </View>

                          <TouchableOpacity
                            testID="closePP"
                            onPress={() => {
                              this.setState({ showPrivacyOverlay: false });
                            }}
                          >
                            <Image
                              testID={"imgCloseOverlay"}
                              style={styles.closeImg}
                              source={imgClose}
                            />
                          </TouchableOpacity>
                        </View>
                        <ScrollView>
                          <View style={styles.margin10}>
                            <Text style={styles.termsText}>
                              {this.state.privacyPolicyData}
                            </Text>
                          </View>
                        </ScrollView>
                      </View>
                    </Overlay>
                  </View>
                </TouchableWithoutFeedback>
              </ScrollView>

              <View style={styles.step2ViewBottom}>
                <View style={styles.termsAndConditionView} testID={"termsAndConditionWrapper"}>
                  <TouchableOpacity {...this.btnTermsAndConditionProps} testID="TCCheck">
                    {!this.state.termsAndConditionAccepted ? (
                      <View testID="emptyCheck" style={styles.checkBoxEmptyStyle} />
                    ) : (
                      <Image
                        testID={"imgEnableRePasswordField"}
                        style={styles.tickImageStyle}
                        {...this.imgTermsAndConditionProps}
                      />
                    )}
                  </TouchableOpacity>
                  <Text style={styles.termsAndConditionStyle}>
                    {`${"I agree to the"} `}
                    <Text
                      testID={"btnLegalTermsAndCondition"}
                      style={styles.btnLegalTermsAndCondition}
                      {...this.btnLegalTermsAndConditionProps}
                    >
                      {this.labelLegalTermCondition}
                    </Text>
                    {`${" and "}`}
                    <Text
                      testID={"btnLegalPrivacyPolicy"}
                      style={styles.btnLegalPrivacyPolicy}
                      {...this.btnLegalPrivacyPolicyProps}
                    >
                      {this.labelLegalPrivacyPolicy}
                    </Text>
                  </Text>
                </View>
                <View style={styles.alignStart}>
                  {!this.isStringNullOrBlank(this.state.tcError) && (
                    <Text style={styles.errorTextTC}>{this.state.tcError}</Text>
                  )}
                </View>

                <TouchableOpacity
                  testID={"btnSignUp"}
                  onPress={() => {
                    this.createAccount();
                  }}
                  style={styles.signUpButtonStyle}
                >
                  <Text style={styles.signUpButtonTextStyle}>
                    {this.btnTextSignUp}
                  </Text>
                </TouchableOpacity>
                <View style={styles.orWrapper}>
                  <View style={styles.horizontalLine} />
                  <View>
                    <Text style={styles.bottomTextStyle}>{this.or}</Text>
                  </View>
                  <View style={styles.horizontalLine} />
                </View>
                <View style={styles.flexRow}>
                  <Text style={styles.bottomTextStyle2}>{`${this.haveAnAccount
                    } `}</Text>
                  <TouchableOpacity
                  testID="DoSignUp"
                    onPress={() => {
                      this.onPressLogin();
                    }}
                  >
                    <Text style={styles.bottomTextStyle3}>{this.login}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getPrivacyPolicy();
    this.getTermsAndCondition();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    paddingHorizontal: deviceBasedDynamicDimension(16, true, 1),
    paddingVertical: deviceBasedDynamicDimension(25, false, 1),
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "rgba(18, 18, 18, 1)",
  },
  flex1: {
    flex: 1
  },
  imagenav_ButtonImageNav: {
    width: deviceBasedDynamicDimension(24, true, 1),
    height: deviceBasedDynamicDimension(24, true, 1),
    resizeMode: "contain",
    marginRight: deviceBasedDynamicDimension(16, true, 1)
  },
  overlayContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: horizontalScale(16),
  },
  successImg: {
    width: deviceBasedDynamicDimension(50, true, 1),
    height: deviceBasedDynamicDimension(50, true, 1),
    resizeMode: "contain",
    marginVertical: deviceBasedDynamicDimension(25, false, 1)
  },
  tickImageStyle: {
    width: deviceBasedDynamicDimension(20, true, 1),
    height: deviceBasedDynamicDimension(20, true, 1),
    resizeMode: "contain",
    marginRight: deviceBasedDynamicDimension(10, true, 1)
  },
  headerTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(18, true, 1)
  },
  lableTitle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: deviceBasedDynamicDimension(16, true, 1)
  },
  successText: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Medium",
    fontSize: deviceBasedDynamicDimension(16, true, 1)
  },
  bgInput: {
    flexDirection: "row",
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#fff",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    marginTop: deviceBasedDynamicDimension(12, false, 1),
    marginBottom: deviceBasedDynamicDimension(16, false, 1),
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    paddingLeft: deviceBasedDynamicDimension(16, true, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  errorText: {
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#ff3b30",
    textAlign: "left",
    marginTop: deviceBasedDynamicDimension(-5, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  errorTextTC: {
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#ff3b30",
    textAlign: "left",
    marginTop: deviceBasedDynamicDimension(-20, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderWidth: 1,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    zIndex: -1,
    marginTop: deviceBasedDynamicDimension(14, false, 1),
    marginBottom: deviceBasedDynamicDimension(14, false, 1)
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: deviceBasedDynamicDimension(14, true, 1),
    color: "#fff",
    textAlign: "left",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    paddingLeft: deviceBasedDynamicDimension(16, true, 1),
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    fontFamily: "Poppins-Regular"
  },
  passwordShowHide: {
    alignSelf: "center",
    marginRight: deviceBasedDynamicDimension(10, true, 1)
  },
  imgPasswordShowhide: {
    height: deviceBasedDynamicDimension(16, true, 1),
    width: deviceBasedDynamicDimension(21, true, 1)
  },

  step2ViewBottom: {
    backgroundColor: "rgba(18, 18, 18, 1)",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: deviceBasedDynamicDimension(8, false, 1),
    paddingBottom: deviceBasedDynamicDimension(32, true, 1),
    paddingHorizontal: deviceBasedDynamicDimension(16, true, 1)
  },
  bottomTextStyle: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(246, 246, 246, 0.6)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: deviceBasedDynamicDimension(16, false, 1),
    width: deviceBasedDynamicDimension(50, true, 1)
  },

  bottomTextStyle2: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(255, 255, 255, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: deviceBasedDynamicDimension(12, true, 1)
  },

  bottomTextStyle3: {
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(255, 182,54, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regulr",
    fontSize: deviceBasedDynamicDimension(12, true, 1)
  },

  termsAndConditionStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    color: "rgba(146, 146, 146, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1)
  },
  btnLegalTermsAndCondition: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1
  },
  btnLegalPrivacyPolicy: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: 0,
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    color: "rgba(255, 182, 54,1)",
    textDecorationLine: "underline",
    borderBottomColor: "rgba(255, 182, 54, 1)",
    borderBottomWidth: 1
  },
  signUpButtonStyle: {
    width: "100%",
    backgroundColor: "rgba(255, 182, 54, 1)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1)
  },
  doneButtonStyle: {
    width: "100%",
    backgroundColor: "rgba(255, 182, 54, 1)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    marginTop: deviceBasedDynamicDimension(25, false, 1),
    marginBottom: deviceBasedDynamicDimension(40, false, 1)
  },

  signUpButtonTextStyle: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "bold",
    includeFontPadding: false,
    color: "rgba(32, 32, 32, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(16, true, 1),
    paddingVertical: deviceBasedDynamicDimension(16, false, 1)
  },

  keyboardPadding: { flex: 1 },

  leagalText: { marginTop: deviceBasedDynamicDimension(10, false, 1) },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },
  headerView: {
    flexDirection: "row",
    marginBottom: deviceBasedDynamicDimension(28, false, 1),
    alignItems: "center"
  },
  termsAndConditionView: {
    flexDirection: "row",
    marginTop: deviceBasedDynamicDimension(8, false, 1),
    marginBottom: deviceBasedDynamicDimension(32, false, 1),
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start"
  },
  orWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: deviceBasedDynamicDimension(42, false, 1),
    marginBottom: deviceBasedDynamicDimension(28, false, 1)
  },
  horizontalLine: {
    flex: 1,
    height: 1,
    backgroundColor: "rgba(246, 246, 246, 0.2)"
  },
  overlayContainerPrivacy: {
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: deviceBasedDynamicDimension(15, true, 1),
    borderTopRightRadius: deviceBasedDynamicDimension(15, true, 1)
  },
  closeImg: {
    width: deviceBasedDynamicDimension(25, true, 1),
    height: deviceBasedDynamicDimension(25, true, 1),
    resizeMode: "contain"
  },
  termsTextHeader: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-SemiBold",
    fontSize: deviceBasedDynamicDimension(16, true, 1),
    lineHeight: deviceBasedDynamicDimension(21, true, 1),
    marginVertical: deviceBasedDynamicDimension(15, false, 1),
  },
  termsText: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins-Regular",
    fontSize: deviceBasedDynamicDimension(12, true, 1),
    lineHeight: deviceBasedDynamicDimension(19, true, 1),
    letterSpacing: 0.07,
    marginVertical: deviceBasedDynamicDimension(10, false, 1),
  },
  checkBoxEmptyStyle: {
    width: deviceBasedDynamicDimension(18, true, 1),
    height: deviceBasedDynamicDimension(18, true, 1),
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.23)",
    borderColor: "rgba(255, 255, 255, 0.45)",
    borderRadius: deviceBasedDynamicDimension(5, true, 1),
    borderWidth: 1,
    marginRight: deviceBasedDynamicDimension(10, true, 1)
  },
  alignStart: { alignSelf: "flex-start" },
  flexRow: { flexDirection: "row" },
  overlayStyle: {
    position: "absolute",
    bottom: 0,
    maxHeight: "95%"
  },
  margin10: {
    marginTop: deviceBasedDynamicDimension(10, false, 1),
    marginBottom: deviceBasedDynamicDimension(25, false, 1)
  },
  overlayContainerHeader: {
    flexDirection: "row",
    borderBottomColor: "rgba(180, 180, 180, 1)",
    borderBottomWidth: 1
  },
  scrollContainer:{ flexGrow: 1, paddingBottom: 200 }
  // Customizable Area End
});
