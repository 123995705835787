import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgTick, imgTickActive } from "./assets";
import { Keyboard } from "react-native";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;

  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;

  termsAndConditionAccepted: boolean;
  showTermsConditionOverlay: boolean;
  showPrivacyOverlay: boolean;
  termsConditionData: string;
  privacyPolicyData: string;

  tcError: string;
  countryError: string;
  mobileError: string;
  showImage: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  phoneReg: RegExp;
  apiPhoneLoginCallId: any;
  privacyPolicyApiCallId: string = "";
  termsConditionApiCallId: string = "";
  labelTitle: string;
  labelHeader: string;
  placeHolderSelectCountry: string;
  defaultPhoneNumber: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTxtLogin: string;
  keyboardDidShowListener: any;
  keyboardDidHideListener: any;
  private readonly errorTitle = "Error";
  // Customizable Area End    

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryCodeSelected: "91",
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "+91",
      enablePasswordField: true,
      checkedRememberMe: false,

      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,

      showTermsConditionOverlay: false,
      showPrivacyOverlay: false,
      termsConditionData: "",
      privacyPolicyData: "",
      termsAndConditionAccepted: true,

      tcError: "",
      countryError: "",
      mobileError: "",
      showImage: true
    };

    this.phoneReg = new RegExp(/^\d{10}$/);

    this.btnTxtLogin = configJSON.btnTxtLogin;
    this.labelTitle = configJSON.labelTitle;
    this.labelHeader = configJSON.labelHeader;
    this.placeHolderSelectCountry = "+91";
    this.defaultPhoneNumber = configJSON.defaultPhoneNumber;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getPrivacyPolicy();
    this.getTermsAndCondition();
    this.keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        this.setState({ showImage: false });
      }
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        this.setState({ showImage: true });
      }
    );
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPhoneLoginCallId !== null &&
      this.apiPhoneLoginCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        const data: any = {
          authToken: responseJson.meta.token,
          mobileNo: this.state.mobileNo,
          screen:this.props.route.params &&
          this.props.route.params.type &&
          this.props.route.params.key? "guest" :  "LogIn",
          type : this.props.route.params.type,
          key: this.props.route.params.key
        };        
        this.props.navigation.navigate("OTPInputAuth", data);
      } else if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0] &&
        responseJson.errors[0].full_phone_number ===
          "Invalid or Unrecognized Phone Number"
      ) {
        this.setState({ mobileError: "Account does not exists." });
      }
    } else if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.privacyPolicyApiCallId
    ) {
      this.setState({ privacyPolicyData: responseJson.data.description || "" });
    } else if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.termsConditionApiCallId
    ) {
      this.setState({
        termsConditionData: responseJson.data.description || ""
      });
    }

    // Customizable Area End
  }

   // Customizable Area Start
  async componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  doMobileLogIn(): boolean {
    this.validateData();

    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      full_phone_number: this.state.countryCodeSelected + this.state.mobileNo
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  btnTermsAndConditionProps = {
    onPress: () => {
      this.imgTermsAndConditionProps.source = !this.state
        .termsAndConditionAccepted
        ? imgTickActive
        : imgTick;
      if (this.state.termsAndConditionAccepted) {
        this.setState({ tcError: configJSON.termsAndConditionError });
      } else {
        this.setState({ tcError: "" });
      }
      this.setState({
        termsAndConditionAccepted: !this.state.termsAndConditionAccepted
      });
    }
  };
  imgTermsAndConditionProps = {
    source: imgTickActive
  };
  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  goToPrivacyPolicy() {
    this.setState({ showPrivacyOverlay: true });
  }

  goToTermsAndCondition() {
    this.setState({ showTermsConditionOverlay: true });
  }
  getPrivacyPolicy() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const getPolicyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCallId = getPolicyMsg.messageId;

    getPolicyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetPrivacyPolicy
    );

    getPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPolicyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPolicyMsg.id, getPolicyMsg);
    return true;
  }

  getTermsAndCondition() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getTermsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsConditionApiCallId = getTermsMsg.messageId;

    getTermsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetTermsAndCondition
    );

    getTermsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTermsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getTermsMsg.id, getTermsMsg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str === undefined || str.length === 0;
  }
  isValidPhoneNumber(data: string) {
    return this.phoneReg.test(data);
  }

  validateData() {
    if (this.isStringNullOrBlank(this.state.mobileNo)) {
      this.setState({ mobileError: configJSON.phoneNumberError });
    } else if (this.state.mobileNo.length !== 10) {
      this.setState({ mobileError: configJSON.phoneNumberErrorLength });
    } else if (!this.isValidPhoneNumber(this.state.mobileNo)) {
      this.setState({ mobileError: configJSON.phoneNumberErrorFormat });
    } else {
      this.setState({ mobileError: "" });
    }

    if (!this.state.termsAndConditionAccepted) {
      this.setState({ tcError: configJSON.termsAndConditionError });
    } else {
      this.setState({ tcError: "" });
    }
  }

  // Customizable Area End
}
