Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelHeader = "Login";
exports.labelTitle = "Login with Mobile Number";
exports.placeHolderSelectCountry = "Select Country";
exports.defaultPhoneNumber = "Enter Mobile Number";
exports.btnTxtLogin = "Continue";

exports.labelLegalTermCondition = "Terms & Conditions";
exports.labelLegalPrivacyPolicy = "Privacy Policy";

exports.countryCodeError = "Country code cannot be empty.";
exports.phoneNumberError = "Phone number cannot be empty.";
exports.phoneNumberErrorLength = "Enter a valid 10 digit Mobile Number.";
exports.phoneNumberErrorFormat = "Enter a valid 10 digit Mobile Number.";
exports.termsAndConditionError = "Please accept Terms & Conditions and Privacy Policy.";

exports.urlGetPrivacyPolicy = "bx_block_privacy_policy/policy";
exports.urlGetTermsAndCondition = "bx_block_termsandconditions/terms_and_conditions";
exports.loginAPiEndPoint = "account_block/accounts/send_otps";
exports.loginAPiMethod = "POST";
exports.validationApiMethodType = "GET";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
// Customizable Area End
